<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2022-06-29 16:14:29
 * @LastEditTime: 2022-06-30 10:44:38
-->
<template>
	<div class="activityData" v-loading="loading">
		<div class="activityData-content">
			<el-tabs v-model="currentTab" @tab-click="changeTab">
				<el-tab-pane label="所有活动数据" name="0"></el-tab-pane>
				<el-tab-pane label="校级活动数据" name="1"></el-tab-pane>
				<el-tab-pane label="院级活动数据" name="2"></el-tab-pane>
			</el-tabs>
			<div class="activityData-search">
				<div class="common-row">
					<div class="common-row-item">
						<span class="common-row-label">活动名称</span>
						<el-input class="common-row-ipt" placeholder="请输入活动名称" v-model="searchForm.activityName" />
					</div>
					<div class="common-row-item">
						<span class="common-row-label">活动类型</span>
						<el-select @change="activityTypeChange" multiple class="common-row-ipt" v-model="activityTypeSelect" placeholder="请选择">
							<el-option v-for="item in activityTypeList" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
					</div>
					<div class="common-row-item">
						<span class="common-row-label">活动时间</span>
						<el-date-picker @input="dateChange" value-format="yyyy-MM-dd" start-placeholder="开始日期"
							end-placeholder="结束日期" range-separator="至" class="common-row-ipt" v-model="searchForm.date"
							type="daterange" placeholder="选择年" @blur="$forceUpdate()" unlink-panels>
						</el-date-picker>
					</div>
				</div>
				<div class="common-row flex-between">
					<div class="flex-item">
						<el-button @click="search" type="primary">查询</el-button>
						<el-button @click="reset">重置</el-button>
					</div>
					<div class="common-row-out">
						<el-button v-preventReClick @click="downLoadFile">导出数据</el-button>
					</div>
				</div>
			</div>
			<div class="common-table">
				<div class="common-table-row">
					<div class="common-table-col">
						<span class="common-table-label">总工时（小时）</span>
						<el-input readonly="readonly" v-model="activityTotal.totalWorkingHours" placeholder="请输入"
							class="common-table-val" />
					</div>
					<div class="common-table-col">
						<span class="common-table-label">活动人次（次）</span>
						<el-input readonly="readonly" v-model="activityTotal.activityPeoNum" placeholder="请输入"
							class="common-table-val" />
					</div>
					<div class="common-table-col">
						<span class="common-table-label">活动数（次）</span>
						<el-input readonly="readonly" v-model="activityTotal.activityNum" placeholder="请输入"
							class="common-table-val" />
					</div>
				</div>
			</div>
			<div class="activityData-table">
				<el-table :data="tableData" border>
					<el-table-column  prop="id" label="活动ID">
					</el-table-column>
					<el-table-column prop="activityName" label="活动名称">
					</el-table-column>
					<el-table-column width="220px" prop="organizer" label="所属组织">
					</el-table-column>
					<el-table-column prop="activityType" label="活动分类">
						<template slot-scope="scope">
							<span>{{scope.row.activityType == 3?"B类":"A类"}}</span>
						</template>
					</el-table-column>
					<el-table-column width="160px" prop="activityType" label="活动类型">
						<template slot-scope="scope">
							<span>{{scope.row.activityType == 1?"一般活动":"共建基地活动"}}</span>
						</template>
					</el-table-column>
					<el-table-column width="120px" prop="totalWorkingHours" label="总工时">
					</el-table-column>
					<el-table-column width="120px" prop="totalPeo" label="活动人次">
					</el-table-column>
					<!-- <el-table-column prop="action" label="操作">
						<template slot-scope="scope">
							<div class="table-action">
								<span @click="actionFn('verify', scope)">{{currentTab == 0 ?'审核':'详情'}}</span>
							</div>
						</template>
					</el-table-column> -->
				</el-table>
				<div class="common-pageNation">
					<el-pagination background @current-change="currentChange" :current-page.sync="pageIndex"
						:page-size="pageSize" layout="total, prev, pager, next" :total="totalCount">
					</el-pagination>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		totalActivity,
		allActivity,
		downLoadActivity,
		downLoadTest,
	} from "@/api/totaldata";
	export default {
		data() {
			return {
				currentTab: "0",
				tableData: [],
				totalCount: 0,
				pageIndex: 1,
				pageSize: 10,
				searchForm: {
					activityTimeStart:"",
					activityTimeEnd:""
				},
				loading:true,
				activityTypeSelect: [],
				activityTotal: {},
				activityTypeList: [{
						label: "一般活动A类",
						value: "1"
					},
					{
						label: "一般活动B类",
						value: "3"
					},
					{
						label: "共建基地活动",
						value: "2"
					},
				],
			};
		},
		mounted() {
			this.defaultDate()
			this.getActivityTotal("1")
		},
		methods: {
			defaultDate() {
				let date = new Date()
				let year = date.getFullYear().toString()  
				let month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1).toString() : (date.getMonth() + 1).toString()  //'04'
				let da = date.getDate() < 10 ? '0' + date.getDate().toString() : date.getDate().toString()  //'12'
				let end = year + '-' + month + '-' + da
				let beg = year + '-' + '01' + '-01'
				this.searchForm.date = [beg, end] 
				this.$set(this.searchForm, "activityTimeStart", beg)
				this.$set(this.searchForm, "activityTimeEnd", end)
			},
			activityTypeChange(e) {
				console.log("activityTypeChange", e)
				if(e.length > 0) {
					this.$set(this.searchForm, "activityType", e.join(","))
				} else {
					this.$set(this.searchForm, "activityType", "")
				}
			},
			changeTab(e) {
				this.currentTab = e.name
				this.pageIndex = 1;
				this.tableData = [];
				this.activityTotal = {};
				this.getActivityTotal("1")
			},
			getActivityTotal(type) {
				this.loading = true
				let params = {}
				if (this.currentTab != 0) {
					this.$set(params, "organizationType", this.currentTab)
				}
				if (this.searchForm.activityName) {
					this.$set(params, "activityName", this.searchForm.activityName)
				}
				if (this.searchForm.activityType) {
					this.$set(params, "activityType", this.searchForm.activityType)
				}
				if (this.searchForm.activityTimeStart) {
					this.$set(params, "activityTimeStart", this.searchForm.activityTimeStart)
				}
				if (this.searchForm.activityTimeEnd) {
					this.$set(params, "activityTimeEnd", this.searchForm.activityTimeEnd)
				}
				if (type == 1) {
					totalActivity(params).then((res) => {
						if (res.code == 0) {
							this.activityTotal = res.data
							this.loading = false
						}

					})
				} else if (type == 2) {
					downLoadActivity(params).then((res) => {
						console.log("downLoadActivity", res)
						const blob = new Blob([res], {
							type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
						})
						// const blob = new Blob([res], {type: "application/vnd.ms-excel;charset=utf-8"})
						if ('download' in document.createElement('a')) {
							// 非IE下载
							const elink = document.createElement('a')
							elink.download = "活动数据表.xlsx"
							elink.style.display = 'none'
							elink.href = URL.createObjectURL(blob)
							document.body.appendChild(elink)
							elink.click()
							URL.revokeObjectURL(elink.href) // 释放URL 对象
							document.body.removeChild(elink)
						} else {
							// IE10+下载
							navigator.msSaveBlob(blob, "活动数据表.xlsx")
						}
					})
				}
				allActivity({
					...params,
					pageIndex: this.pageIndex,
					pageSize: this.pageSize,
				}).then((res) => {
					if (res.code == 0) {
						this.tableData = res.data
						this.totalCount = res.page.totalCount * 1
					}
				})
			},
			downLoadFile() {
				this.getActivityTotal("2")
			},
			currentChange(val) {
				this.pageIndex = val;
				this.tableData = [];
				this.activityTotal = {};
				this.getActivityTotal("1");
			},
			search() {
				this.pageIndex = 1;
				this.tableData = [];
				this.activityTotal = {};
				this.getActivityTotal("1")
				
			},
			reset() {
				this.activityTypeSelect = []
				this.searchForm = {}
				this.pageIndex = 1;
				this.tableData = [];
				this.activityTotal = {};
				this.getActivityTotal("1")
			},
			dateChange(e) {
				console.log("dateChange", e)
				if (e.length > 0) {
					this.$set(this.searchForm, "activityTimeStart", e[0])
					this.$set(this.searchForm, "activityTimeEnd", e[1])
				} else {
					this.$set(this.searchForm, "activityTimeStart", "")
					this.$set(this.searchForm, "activityTimeEnd", "")
				}
				
			},
			actionFn(type, scope) {
				switch (type) {
					case "verify":
						this.$router.push({
							path: "/voteSet/detail"
						})
						break;
				}
			}
		}
	};
</script>

<style lang="scss" scoped>
	/deep/.el-input__inner {
		height: 36px;
		border-radius: 0px 2px 2px 0px;
		border: 0;
	}

	/deep/.el-button {
		height: 36px;
	}

	/deep/.common-row-out .el-button--default {
		border: 1px solid #DCDFE6 !important;
		color: #606266 !important;
	}

	.flex-between {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.flex-item {
		display: flex;
		align-items: center;
	}

	.common-table-label {
		width: 192px !important;
	}

	.activityData {
		.activityData-content {
			padding: 0 20px 20px 20px;

			.activityData-search {
				.common-row {
					margin: 16px 0;

					.common-row-item {
						margin-right: 20px;

						.common-row-ipt {
							flex: 1;
							height: 36px;
							line-height: 36px;
							border: 1px solid #EDF1F7;
							border-left: 0;
						}
					}
				}
			}

			.activityData-table {
				margin-top: 20px;
			}
		}
	}
</style>
