/*
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2022-07-20 17:27:12
 * @LastEditTime: 2022-08-20 10:37:41
 */
import axios from '@/utils/request'
let Api = {
    activityTotal: "/activity/statistics",
    activityAll: "/activity/activityList",
    volunteerTotal: "/volunteer/information/statistics",
    volunteerAll: "/volunteer/information/volunteerTotal",
    activityDownload: "/activity/activityExport",
    volunteerDownload: "/volunteer/information/export",
}
// 活动数据统计
export function totalActivity(params) {
    return axios({
        method: 'get',
        url: Api.activityTotal,
        params: params
    })
}
// 活动列表统计
export function allActivity(params) {
    return axios({
        method: 'get',
        url: Api.activityAll,
        params: params
    })
}
// 志愿者数据统计
export function totalVolunteer(params) {
    return axios({
        method: 'get',
        url: Api.volunteerTotal,
        params: params
    })
}
// 志愿者列表统计
export function allVolunteer(params) {
    return axios({
        method: 'get',
        url: Api.volunteerAll,
        params: params
    })
}
// 活动数据导出
export function downLoadActivity(params) {
    return axios({
        method: 'get',
        url: Api.activityDownload,
		params: params,
		headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json;charset=UTF-8'
		},
		withCredentials: true,
		observe: 'response',
		responseType: 'arraybuffer',
    })
}
// 志愿者导出
export function downLoadVolunteer(params) {
    return axios({
        method: 'get',
        url: Api.volunteerDownload,
        params: params,
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json;charset=UTF-8'
		},
		withCredentials: true,
		observe: 'response',
		responseType: 'arraybuffer',
    })
}
